import { useAppSelector } from '@openware/opendax-web-sdk'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'

interface EmptyStateProps {
  label: React.ReactNode
  link?: string
  buttonLabel?: React.ReactNode
}

export const EmptyState: React.FC<EmptyStateProps> = ({ label, link, buttonLabel }: EmptyStateProps) => {
  const userRole = useAppSelector(state => state.user.user.role)
  const isAdminWriter = ['superadmin', 'writer'].includes(userRole)

  return (
    <div className="w-full h-full flex justify-center items-center lg:mb-20">
      <div className="w-[134px] h-[104px] relative mr-4">
        <Image src="/images/ufo.png" alt="empty" layout="fill" />
      </div>
      <div className="flex flex-col justify-center">
        <span className="text-text-color-40 text-2xl font-gilmer-medium mb-2">{label}</span>
        {isAdminWriter && link && buttonLabel && (
          <Link href={link}>
            <a className="text-sm font-metro-semibold bg-neutral-control-color-0 border border-neutral-control-color-70 text-neutral-control-layer-color-70 hover:bg-neutral-control-color-50 hover:text-neutral-control-layer-color-100 hover:border-opacity-0 rounded-md px-3 py-1 w-fit shadow-sm pointer">
              {buttonLabel}
            </a>
          </Link>
        )}
      </div>
    </div>
  )
}
