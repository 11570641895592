import classnames from 'classnames'
import Link from 'next/link'
import { FC, useCallback, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { NextIcon, PrevIcon } from '../../assets/images/Arrow'

export interface PaginationProps {
  /**
   * Number shows first element index in pagination
   */
  firstIndex: number
  /**
   * Number shows last element index in pagination
   */
  lastIndex: number
  /**
   * Number shows total amount of elements
   */
  total: number
  /**
   * Number of pagination buttons to show around the current page button
   */
  visualSize: number
  /**
   * Number shows current page index
   */
  page: number
  /**
   * Value for defining if next page exist or not
   */
  nextPageExists: boolean
  /**
   * Number of rows per page
   */
  rowsPerPage: number
  /**
   * Base path before page
   */
  basePath: string
  /**
   * Determines where pagination positioning:
   * on the center or on the right side
   */
  isPaginationOnCenter?: boolean
  /**
   * Mobile Version
   */
  isMobile?: boolean
  /**
   * Default query string params
   */
  baseQueryString?: string
}

/**
 * Pagination component helper for tables
 */
const Pagination: FC<PaginationProps> = (props: PaginationProps) => {
  const {
    firstIndex,
    lastIndex,
    visualSize,
    total,
    page,
    rowsPerPage,
    nextPageExists,
    basePath,
    isPaginationOnCenter,
    isMobile,
    baseQueryString,
  } = props
  const prevDisabled = page === 0
  const [pages, setPages] = useState<number[]>([])
  const [firstPage, setFirstPage] = useState<number>(0)
  const [lastPage, setLastPage] = useState<number>(0)
  const [hoveredPage, setHoveredPage] = useState<number>(-2)
  const [currentStartPage, setCurrentStartPage] = useState<number>(0)
  const [currentEndPage, setCurrentEndPage] = useState<number>(0)

  useEffect(() => {
    const half = Math.floor(visualSize / 2)
    const lastPage = Math.ceil(total / rowsPerPage)
    const current = page ? page + 1 : 1
    const startPage = current - half < 1 ? 1 : current === lastPage ? current - half - 1 : current - half
    const endPage =
      current + half > lastPage ? lastPage : startPage < half + 1 ? visualSize - 1 + startPage : current + half
    const pagesToSet = []

    if (lastPage <= visualSize) {
      for (let i = 1; i <= lastPage; i++) {
        pagesToSet.push(i)
      }
      setPages(pagesToSet)
      setFirstPage(0)
      setLastPage(0)
      setCurrentStartPage(1)
      setCurrentEndPage(lastPage)
      return
    }

    for (let i = startPage; i <= endPage; i++) {
      pagesToSet.push(i)
    }
    setPages(pagesToSet)
    setFirstPage(current - half > 1 ? 1 : 0)
    setLastPage(current + half < lastPage ? lastPage : 0)
    setCurrentStartPage(startPage)
    setCurrentEndPage(endPage)
  }, [page, total])

  const cnLink =
    'border-y border-x-[0.5px] min-w-[34px] sm:min-w-[36px] p-1.5 sm:p-2 inline-block align-middle border-divider-color-20'
  const cnCurrentLink = useCallback(
    (p: number, disabled: boolean) =>
      classnames(cnLink, {
        'border-primary-cta-color-80 bg-primary-cta-color-10 text-neutral-control-layer-color-100': p === page + 1,

        'hover:bg-primary-cta-color-10 hover:border-primary-cta-color-80 cursor-pointer': !disabled,
      }),
    [page, hoveredPage],
  )

  const cnDots = classnames(cnLink, 'border-x-[0.5px] hover:none')

  const handleHover = useCallback((index: number) => {
    return () => {
      setHoveredPage(index)
    }
  }, [])

  return (
    <div className="flex flex-col-reverse items-center text-text-color-70 font-metro-regular md:flex-row md:justify-between my-4 text-sm max-w-[100vw] mx-0">
      {!isMobile && (
        <p className="p-0">
          <FormattedMessage
            id="page.assets.paginanation.label"
            values={{
              from: <span className="font-metro-semibold">{firstIndex}</span>,
              to: <span className="font-metro-semibold">{lastIndex}</span>,
              total: <span className="font-metro-semibold">{total}</span>,
            }}
          />
        </p>
      )}
      <div className="flex text-center font-metro-semibold text-neutral-control-layer-color-60">
        {page < 1 ? (
          <div className={classnames('flex rounded-l-md border-r-0.5 border-l', cnCurrentLink(0, true))}>
            <PrevIcon fill="currentColor" disabled={prevDisabled} />
          </div>
        ) : (
          <Link
            href={
              page === 1
                ? baseQueryString
                  ? `${basePath}?${baseQueryString}`
                  : `${basePath}`
                : `${basePath}?${[`page=${page}`, baseQueryString].filter(Boolean).join('&')}`
            }
          >
            <div
              onMouseOver={handleHover(0)}
              onMouseLeave={handleHover(-2)}
              className={classnames(cnCurrentLink(0, false), 'flex rounded-l-md border-r-0.5 border-l')}
            >
              <PrevIcon fill="currentColor" disabled={prevDisabled} />
            </div>
          </Link>
        )}
        {firstPage > 0 ? (
          <div className="flex flex-row">
            <Link href={baseQueryString ? `${basePath}?${baseQueryString}` : `${basePath}`}>
              <div
                onMouseOver={handleHover(firstPage)}
                onMouseLeave={handleHover(-2)}
                className={cnCurrentLink(firstPage, false)}
              >
                {firstPage}
              </div>
            </Link>
            {currentStartPage - firstPage > 1 && <div className={cnDots}>...</div>}
          </div>
        ) : null}
        {pages.map(p => (
          <Link
            key={p}
            href={
              p === 1
                ? baseQueryString
                  ? `${basePath}?${baseQueryString}`
                  : `${basePath}`
                : `${basePath}?${[`page=${p}`, baseQueryString].filter(Boolean).join('&')}`
            }
          >
            <div onMouseOver={handleHover(p)} onMouseLeave={handleHover(-2)} className={cnCurrentLink(p, false)}>
              {p}
            </div>
          </Link>
        ))}
        {lastPage > 0 ? (
          <div className="flex flex-row">
            {Math.ceil(total / rowsPerPage) - currentEndPage > 1 && <div className={cnDots}>...</div>}
            <Link href={`${basePath}?${[`page=${lastPage}`, baseQueryString].filter(Boolean).join('&')}`}>
              <div
                onMouseOver={handleHover(lastPage)}
                onMouseLeave={handleHover(-2)}
                className={cnCurrentLink(lastPage, false)}
              >
                {lastPage}
              </div>
            </Link>
          </div>
        ) : null}
        {nextPageExists ? (
          <Link href={`${basePath}?${[`page=${page + 2}`, baseQueryString].filter(Boolean).join('&')}`}>
            <div
              onMouseOver={handleHover(Math.ceil(total / rowsPerPage) + 1)}
              onMouseLeave={handleHover(-2)}
              className={classnames(
                cnCurrentLink(Math.ceil(total / rowsPerPage) + 1, false),
                'flex rounded-r-md border-l-0.5 border-r',
              )}
            >
              <NextIcon fill="currentColor" disabled={false} />
            </div>
          </Link>
        ) : (
          <div
            className={classnames(
              cnCurrentLink(Math.ceil(total / rowsPerPage) + 1, true),
              'flex rounded-r-md border-l-0.5 border-r',
            )}
          >
            <NextIcon fill="currentColor" disabled />
          </div>
        )}
      </div>
      {isPaginationOnCenter && <div className="flex w-8" />}
    </div>
  )
}

export { Pagination }
