import { convertNumberToLiteral, Decimal, getConfigs } from '@openware/opendax-web-sdk'
import classNames from 'classnames'
import { EmptyState } from 'components/EmptyState'
import { LandingBlockHeader } from 'components/Landing'
import { Pagination } from 'components/Pagination'
import { Table } from 'components/Table'
import { getChangeClass, renderPrice } from 'helpers'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { Asset, Category } from '../../types'

interface AssetsTableProps {
  assets: Asset[]
  start: number
  end: number
  total: number
  lastPage: number
  rowsPerPage: number
  isMobile: boolean
  category?: Category
  currentCategoryName?: string
}

const AssetsTable: FC<AssetsTableProps> = ({
  assets,
  start,
  end,
  total,
  lastPage,
  rowsPerPage,
  isMobile,
  currentCategoryName,
  category,
}: AssetsTableProps) => {
  const router = useRouter()
  const currentPage = 1
  const { platformCharSymbol } = getConfigs()

  React.useEffect(() => {
    localStorage.setItem('lastAssetsPage', String(currentPage))
  }, [currentPage])

  const tableHeader = React.useMemo(
    () => [
      <span key={0} className="text-left block py-4 px-2">
        <FormattedMessage id="page.assets.table.number" />
      </span>,
      <span key={1} className="text-left block py-4 px-2 w-64 md:w-56 2xl:w-64">
        <FormattedMessage id="page.assets.table.assets" />
      </span>,
      <span key={2} className="text-left block py-4 px-2 w-full min-w-max">
        <FormattedMessage id="page.assets.table.price" />
      </span>,
      <span key={4} className="text-left block py-4 px-2 w-full min-w-max">
        <FormattedMessage id="page.assets.table.24hours.change" />
      </span>,
      <span key={6} className="text-left block py-4 px-2 w-full min-w-max">
        <FormattedMessage id="page.assets.table.volume" />
      </span>,
      <span key={8} className="text-right block py-4 px-2 w-full min-w-max">
        <FormattedMessage id="page.assets.table.marketCap" />
      </span>,
    ],
    [],
  )

  const basePath = React.useMemo(() => {
    const { categoryID } = router.query

    if (!categoryID) {
      return '/assets'
    }

    return `/assets/${categoryID}`
  }, [router])

  const tableData = React.useCallback(() => {
    return assets
      ? assets.map((asset: any, i: number) => {
          const cnPrice24h = asset.price_change_24h ? getChangeClass(asset.price_change_24h) : 'text-text-color-90'
          const position = start + i + 1

          let href = `/asset/${asset.code}`

          if (category) {
            href += `?category=${category.category}`
          }

          return [
            <Link href={href} key="link-1">
              <a key={i} className="px-2 py-3 font-metro-regular text-text-color-60">
                {position}
              </a>
            </Link>,
            <Link href={href} key="link-2">
              <a key={i} className="p-2 flex items-center min-w-full w-64 md:w-56 2xl:w-64">
                <div className="relative w-[32px] aspect-square">
                  <Image
                    alt={asset.code}
                    src={asset.logo.startsWith('http') ? asset.logo : '/images/icons/missing_small.png'}
                    layout="fill"
                    objectFit="contain"
                  />
                </div>

                <span className="text-base block text-text-color-100 font-metro-bold mx-2 whitespace-nowrap">
                  {asset.code.toUpperCase()}
                </span>

                <span className="text-text-color-60 text-base font-metro-normal w-full block whitespace-nowrap">
                  {asset?.name ? asset.name : asset?.code.toUpperCase()}
                </span>
              </a>
            </Link>,

            <Link href={href} key="link-3">
              <a
                key={i}
                className="px-2 py-3 text-text-color-90 font-gilmer-semibold text-sm text-left w-full min-w-max"
              >
                {`${platformCharSymbol}${renderPrice(asset.price)}`}
              </a>
            </Link>,
            <Link href={href} key="link-4">
              <a
                key={i}
                className={classNames('px-2 py-3 text-left font-gilmer-semibold text-sm w-full min-w-max', cnPrice24h)}
              >
                {asset.price_change_24h && !isNaN(asset.price_change_24h)
                  ? `${Decimal.format(asset.price_change_24h, 2, ',')}%`
                  : '?'}
              </a>
            </Link>,
            <Link href={href} key="link-5">
              <a key={i} className="px-2 py-3 text-text-color-90 font-gilmer-normal text-sm text-left w-full min-w-max">
                {asset.volume_24h && !isNaN(asset.volume_24h)
                  ? `${platformCharSymbol}${convertNumberToLiteral(asset.volume_24h, ['', 'M'])}`
                  : '?'}
              </a>
            </Link>,
            <Link href={href} key="link-6">
              <a
                key={i}
                className="px-2 py-3 text-text-color-90 font-gilmer-normal text-sm text-right block w-full min-w-max"
              >
                {asset.market_cap && !isNaN(asset.market_cap)
                  ? `${platformCharSymbol}${convertNumberToLiteral(asset.market_cap, ['', 'M'])}`
                  : '?'}
              </a>
            </Link>,
          ]
        })
      : []
  }, [assets, renderPrice, getChangeClass, start, category])

  const getRowLink = React.useCallback(
    (key: string) => {
      const asset = assets[Number(key)]
      let href = `/asset/${asset.code}`

      if (category) {
        href += `?category=${category.category}`
      }
      return href
    },
    [assets, category],
  )

  return (
    <div className="flex flex-col w-full overflow-x-hidden">
      <div className="px-[18px] sm:px-6 lg:px-0">
        <LandingBlockHeader
          ContainerTag='h1'
          title={
            <span className="text-text-color-100 font-metro-semibold text-xl">
              {currentCategoryName ? (
                <>
                  {currentCategoryName} <FormattedMessage id="page.assets.title.topAssets" />
                </>
              ) : (
                <FormattedMessage id="page.assets.title.topAssets.all" />
              )}
            </span>
          }
        />
      </div>
      <div className="overflow-auto max-w-[100vw] px-[18px] sm:px-6 lg:px-0 with-scrollbar">
        <Table
          label="Assets table"
          header={tableHeader}
          data={tableData()}
          getRowLink={getRowLink}
          noDataMessage={<EmptyState label={<FormattedMessage id="page.assets.table.emptyState.label" />} />}
        />
      </div>
      {tableData().length > 0 && (
        <div className="mx-6 lg:mx-0">
          <Pagination
            firstIndex={start + 1}
            lastIndex={end + 1 > total ? total : end + 1}
            total={total}
            visualSize={isMobile ? 3 : 5}
            page={currentPage - 1}
            nextPageExists={lastPage > currentPage}
            rowsPerPage={rowsPerPage}
            basePath={basePath}
            isMobile={isMobile}
          />
        </div>
      )}
    </div>
  )
}

export default AssetsTable
