import classNames from 'classnames'
import Link from 'next/link'
import React, { FC, ReactNode, useEffect, useState } from 'react'

export type CellData = string | number | React.ReactNode | undefined

export interface TableState {
  /**
   * Key of selected row
   */
  selectedRowKey?: string
}

export interface TableProps {
  /**
   * Data which is used to render Table. The first element
   * of array is used to render table head unless `noHead`
   * is true. the rest is used to render Table body.
   *
   * All the elements of an array should have the same length.
   */
  data: CellData[][]
  /**
   * Renders table head.
   */
  header?: React.ReactNode[]
  /**
   * Row's unique key, could be a number - element's index in data
   */
  rowKeyIndex?: number
  /**
   * Key of selected row, could be a string
   */
  selectedKey?: string
  /**
   * Returns link to redirect on row click
   */
  getRowLink?: (key: string) => string
  /**
   * Sets row background color
   */
  rowBackgroundColor?: string
  /**
   * Sets colspan count for empty table
   */
  colSpan?: number
  /**
   *  No data message for empty state of table
   */
  noDataMessage?: string | ReactNode
  /**
   * Table label for accessibility
   */
  label: string
}

/**
 * Cryptobase Table overrides default table
 */
export const Table: FC<TableProps> = ({
  data,
  header = [],
  rowKeyIndex,
  getRowLink,
  selectedKey,
  noDataMessage,
  label,
}) => {
  const [selectedRowKey, setSelectedRowKey] = useState<string>('')

  const handleSelect = (key: string) => () => {
    setSelectedRowKey(key)
  }

  const renderHead = (row: CellData[]) => {
    const cells = row.map((c, index) =>
      c ? (
        <th className="border-y border-divider-color-20 p-0" key={index}>
          {c}
        </th>
      ) : (
        <th className="border-y border-divider-color-20 p-0" key={index}>
          &nbsp;
        </th>
      ),
    )

    return (
      <thead className="text-xs text-text-color-60 font-metro-semibold leading-none">
        <tr>{cells}</tr>
      </thead>
    )
  }

  const renderPlainCells = (row: CellData[] = []) => {
    return row.length > 0 ? (
      row.map((c, index: number) => (
        <td className="p-0 table-cell border-b border-divider-color-20 h-full align-middle" key={index}>
          {c}
        </td>
      ))
    ) : (
      <td className="cr-table--no-data" colSpan={header && header.length}>
        No data to show
      </td>
    )
  }

  const renderLinkCells = (row: CellData[] = [], rowLink: string) => {
    return row.length > 0 ? (
      row.map((cell, index: number) => (
        <td className="p-0 table-cell border-b border-divider-color-20 h-full align-middle" key={index}>
          <Link href={rowLink}>{cell}</Link>
        </td>
      ))
    ) : (
      <td className="cr-table--no-data" colSpan={header && header.length}>
        No data to show
      </td>
    )
  }

  const renderSingleRow = (row: CellData[], rowKey: string) => {
    const rowClassName = (key: string) =>
      classNames('table-row cursor-pointer leading-normal hover:bg-neutral-control-color-10', {
        'leading-snug bg-neutral-control-color-10': selectedRowKey === key,
      })

    if (getRowLink) {
      const rowLink = getRowLink(rowKey)

      return (
        <tr key={rowKey} className={rowClassName(rowKey)}>
          {renderLinkCells(row, rowLink)}
        </tr>
      )
    }

    return (
      <tr data-testid="tr" className={rowClassName(rowKey)} key={rowKey} onClick={handleSelect(rowKey)}>
        {renderPlainCells(row)}
      </tr>
    )
  }

  const renderBody = (rows: CellData[][], rowKeyIndexValue: number | undefined) => {
    const rowElements = rows.map((row, i) => {
      const rowKey = String(rowKeyIndexValue !== undefined ? row[rowKeyIndexValue] : i)

      return renderSingleRow(row, rowKey)
    })

    return <tbody className="text-base table-row-group">{rowElements}</tbody>
  }

  useEffect(() => {
    if (selectedKey) setSelectedRowKey(selectedKey)
  }, [selectedKey])

  return (
    <div itemScope itemType="https://schema.org/Table">
      <table className="w-max sm:w-full table" summary={label}>
        {renderHead(header)}
        {renderBody(data, rowKeyIndex)}
      </table>
      {!data.length && <div className="my-20 w-full whitespace-nowrap">{noDataMessage}</div>}
    </div>
  )
}
