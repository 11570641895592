export const POWERED_OPTIONS = {
  item: [
    {
      name: 'Discover the YELLOW Token',
      href: '/discover_yellow_token',
      icon: 'yellow',
    },
    {
      name: 'Join the Yellow Network',
      href: '/join_yellow_network',
      icon: 'tokens',
    },
    {
      name: 'List your token',
      href: '/list_your_token',
      icon: 'articles',
    },
  ],
}
